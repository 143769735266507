import vg from '../assets/tp.mp4';
import { Button, Container } from "react-bootstrap"



import '../App.css';
import { Link } from 'react-router-dom';

function Header() {

  return (

    <div fluid className="position-relative p-0">
      <video src={vg} autoPlay loop muted className="w-100 h-100 position-fixed" style={{ objectFit: 'cover',height: '100vh' }} />
      <Container className="position-relative d-flex flex-column justify-content-center align-items-center text-center" style={{ height: '100vh', zIndex: 1 }}>
        <div className="mb-0 font-weight-bold 
          display-4 display-md-5 display-lg-6 display-xl-7 text-light">
          <h2 className='thp'>The Happy Place</h2>
          <h5>Inspire. Create. Ingenuinity</h5>
          <Link to="/about-us"><Button variant="outline-info" className='btn'>LEARN MORE</Button></Link>

        </div>
        
      </Container>
    </div>
  );
}

export default Header
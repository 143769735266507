import {Route, Routes } from 'react-router-dom';
import Header from '../pages/Header';
import Services from '../pages/Services';
import About from './About';
import Book from './Book';

function Pages() {
    return(
        <>
        <Routes>
            <Route path='/' element={<Header />}></Route>
            <Route path='/about-us' element={<About />}></Route>
            <Route path='/gallery' element={<Services />}></Route>
            <Route path='/donate' element={<Book />}></Route>
        </Routes>
        </>
    );
}

export default Pages;
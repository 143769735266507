import { Button, Row, Col, Container, } from "react-bootstrap";
import vg from '../assets/thp-light.mp4';

function Book() {
  return (
    <div className='donate'>
      <h2 className=" donate-center">Partner With Us </h2>
      <br />
      <Row>
        <Col md={6}>
          <video src={vg} autoPlay loop muted />
        </Col>
        <Col md={6}>
          <Container className="position-relative d-flex flex-column justify-content-center align-items-justify text-justify" style={{ zIndex: 1 }}>
            <div className="mb-0 ">
              <p>
                The Centre for Innovative Communications, Technology, Visual, and Performance Art will represent more than a mere physical space. Through a rich tapestry of workshops, exhibitions, performances, and interactive experiences, we aim to inspire a new generation of artists, communicators, and performers who are unafraid to challenge norms and embrace the unconventional. The Centre will stand as a vibrant testament to the continent's ability to fuse its rich cultural heritage with cutting-edge technology, ultimately fostering creativity, innovation, and sustainable progress. The center will become the driving force behind cultivating talent, fostering collaboration, and shaping a brighter future for Zambia and Africa's creative and technological landscape. To donate, click the button 😊 and thank you!
              </p>
              <Button variant="outline-info" className="btn donate-btn"> DONATE NOW </Button>

            </div>

          </Container>

        </Col>
      </Row>
      <br />
      <h2 className="thp donate-center">What is Pending</h2>
      <br />
      <Row>
        <Col md={6}>
          <ul>
            <li>A disabled-friendly ramp that runs from the ground floor to the top floor.</li>
            <li>A staircase</li>
            <li> 6 Sinks, 9 toilets, 3 urinals, 7 wash basins, 1 kitchen sink</li>
            <li>Kitchen unit</li>
            <li>Window frames and tempered glass windows</li>
            <li>Door frames and tempered glass doors</li>
            <li>Chairs for the theatre</li>
          </ul>
        </Col>
        <Col md={6}>
          <ul>
            <li>Ceiling for the top floor</li>
            <li>Landscaping</li>
            <li>Paving for the grounds and access road.</li>
            <li>Connection to the main electricity grid</li>
            <li>Painting all the units</li>
            <li> Smoothening and tiling the floors</li>
            <li>Air conditioners and installation</li>
            <li>Stage, lighting, and sound equipment for the theatre</li>
          </ul>
        </Col>
      </Row>

    </div>
  )
}

export default Book;
import { HashRouter } from 'react-router-dom';
import Pages from './pages/Pages';
import './App.css';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

function App() {
  return (
    <div>
      <HashRouter>
        <Navigation />
        <Pages />
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;

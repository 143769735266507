import { BsSuitHeartFill, BsInstagram } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { CiLocationOn } from 'react-icons/ci';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ig from "../assets/ig.png"
import maps from "../assets/maps.png"
import email from "../assets/email.png"
import { color } from 'framer-motion';

function Footer() {
  return (

    <div className='footer'>

      <Row>
        <Col lg={6}>

          <h6 className='footer-link'>  <Link to="https://www.instagram.com/buildingthehappyplace/"><img width={20} src={ig} alt="" /></Link>Follow us on Instagram
          </h6>

          <h6 className='footer-link'>
            <img src={email} alt="" width={20} /> info@letsdogoodzambia.com
          </h6>
          <h6>
            <img src={maps} alt="" width={20} /> 3042L Zambezi Road
            , Foxdale Lusaka
          </h6>

        </Col>
        <Col lg={6}>

          <h6>  <Link className="footer-link" to="/about-us"> <b> About Us </b></Link>
          </h6>

          <h6>
            <Link className="footer-link" to="/gallery">
              <b> Gallery </b>
            </Link>

          </h6>
          <h6>
            <Link className="footer-link" to="/donate">
              <b>Donate</b>
            </Link>
          </h6>

        </Col>

      </Row>

      <h6 className='center'>Made with <i>  <BsSuitHeartFill /> </i> by <a className='ideate green' href="https://ideate.letsdogood.app/" target='_blank'> Ideate Innovations</a> &copy; 2023</h6>
    </div>
  )
}

export default Footer
import { Row, Card, Col } from "react-bootstrap";
import img1 from '../assets/1.jpg'
import img2 from '../assets/2.jpg'
import img3 from '../assets/3.jpg'
import img4 from '../assets/4.jpg'
import img6 from '../assets/6.jpg'
import img7 from '../assets/7.jpg'
import img9 from '../assets/9.jpg'

function About() {


  return (
    <>
      <div className='about-section'>

        <div className="intro">

          <p className=''>
            Imagine a place where creativity, technology, and culture come together in a vibrant dance – that's the Centre for Innovative Communications,
            Digital Technology, Visual, and Performance Art (The Happy Place). We envision a vibrant, inspired, and dynamic space where artists of various kinds— creatives,
            activists, software developers, and anyone curious about the endless possibilities of artistic expression—can come to unleash their potential and
            thrive. We invite you to partner with us in making this transformative endeavour a reality.
          </p>
        </div>

        <div className="goals">
          <h1 className="footer-link thp">Functions Of The Hub</h1>
          <div className="row">
            <Col md={1}>
            </Col>
            <Col md={10}>
              <br />
              <ul className="list-left green">
                <li> <b>Skill Development and Training:</b> offering training in media, digital technology, communication, and art forms like visual and performance.  </li>
                <li> <b>Promote Collaboration:</b>
                among artists, developers, researchers, entrepreneurs, creatives, and activists to create art-tech projects that respond to social and environmental challenges. The innovation lab will also experiment with emerging technologies like virtual and augmented reality, explore artificial intelligence and immersive art. </li>
                                <li> <b>Cultural Preservation and Innovation:</b> celebrating and preserving the African cultural heritage through digital platforms, showcasing paintings, music, dance, theatre, and storytelling. . </li>
                <li><b> Media Production and Communication:</b> recording performances, creating content that reflects African narratives and perspectives and addresses social challenges while celebrating culture.  </li>
                <li><b> Entrepreneurship and Economic Growth:</b> a creative haven, nurturing dreams, supporting start-ups, generating jobs, turning ideas into reality. </li>
                <li><b>Community Engagement:</b> activities will include outreach programmes including therapy, exhibitions, workshops, and live performances. This will promote culture exchange and create a sense of ownership, pride, inclusion, and equality.</li>
                <li><b>Transcending Borders — a global Canvas:</b> 
                The centre isn't just for Zambia or Africa – it’ll be a window to the world facilitating collaborations with global creative institutions and exchange of ideas and experiences with international partners.                </li>
              </ul>
            </Col>
            <Col md={1}>
            </Col>
          </div>

        </div>


        <div className="our-features">
          <h1 className="footer-link thp">Features Of The Happy Place</h1>
          <Row className="justify-content-center">
            <Col md={3}>
              <Card className="mx-auto features ">
                <Card.Img variant="top" src={img6} className="card-img" />
                <Card.Body>
                  <Card.Title>
                    <h6 className="footer-link"> <b> Learning Hub </b></h6>
                  </Card.Title>
                  <Card.Text>
                    <p className="features-text">
                      Will offer workshops and master classes that cater to a broad spectrum of individuals seeking to hone their art, creative, communication, entrepreneurial, leadership, sustainability, and digital technological prowess.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mx-auto features">
                <Card.Img variant="top" src={img3} className="card-img" />
                <Card.Body>
                  <Card.Title>
                    <h6 className="footer-link"><b>Gallery</b></h6>
                  </Card.Title>
                  <Card.Text>
                    <p className="features-text">
                      Will house interactive exhibits including paintings, photographs, and documentaries, some created by our “ art students”. Part of the funds from art sales will be invested as start-up capital for our “students” or pay for talented students unable to afford the courses or supplies.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <a className="ideate" href="https://ideate.letsdogood.app/" target="_blank" rel="noopener noreferrer">
                <Card className="mx-auto features">
                  <Card.Img variant="top" src={img4} className="card-img" />
                  <Card.Body>
                    <Card.Title>
                      <h6 className="footer-link"><b>Digital Innovation Lab </b></h6>
                    </Card.Title>
                    <Card.Text>
                      <p className="features-text">
                        where IDeate Innovations, a Tech4Good digital social enterprise is hosted. The lab will develop software, digital tools and platforms, host hackathons, digital safety, and wellbeing sessions, equip under-served communities with digital skills like coding.
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col md={3}>
              <Card className="mx-auto features">
                <Card.Img variant="top" src={img9} className="card-img" />
                <Card.Body>
                  <Card.Title>
                    <h6 className="footer-link"><b>Brave Space</b></h6>
                  </Card.Title>
                  <Card.Text>
                    <p className="features-text">
                      Bringing therapy closer to the people will be a priority to ensure emotional well-being. This is where one-on-one counselling and group therapy will take place.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3}>
              <Card className="mx-auto features">
                <Card.Img variant="top" src={img1} className="card-img" />
                <Card.Body>
                  <Card.Title>
                    <h6 className="footer-link"><b>Book shop</b></h6>
                  </Card.Title>
                  <Card.Text>
                    <p className="features-text">
                      A space where Zambian and African literature will be sold.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mx-auto features">
                <Card.Img variant="top" src={img2} className="card-img" />
                <Card.Body>
                  <Card.Title>
                    <h6 className="footer-link"><b>Ultra Modern Theatre </b></h6>
                  </Card.Title>
                  <Card.Text>
                    <p className="features-text">
                      For live performances, intimate concerts, dance and award shows.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mx-auto features">
                <Card.Img variant="top" src={img7} className="card-img" />
                <Card.Body>
                  <Card.Title>
                    <h6 className="footer-link"><b>Top Floor Terrace Restaurant</b></h6>
                  </Card.Title>
                  <Card.Text>
                    <p className="features-text">
                      A modern restaurant overlooking Foxdale forest that will offer local and international cuisine.
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card></Col>
          </Row>

        </div>




      </div>
    </>

  )
}

export default About
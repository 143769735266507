import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <Navbar className='white' sticky="top" expand="lg">
            <Container>
                <Navbar.Brand href="/" >
                    <h1 className='thp'>
                        The Happy Place
                    </h1>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link>
                            <Link className='nav-link' to="/about-us">
                                ABOUT US
                            </Link>
                        </Nav.Link>
                        <Nav.Link >
                            <Link className='nav-link' to="/gallery">
                                GALLERY
                            </Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link className='nav-link' to="/donate">
                                DONATE
                            </Link>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;

import { Carousel } from 'react-bootstrap';
import '../App.css';

// import images 
import img1 from '../assets/gallery/1.png'
import img2 from '../assets/gallery/2.jpg'
import img3 from '../assets/gallery/3.png'
import img4 from '../assets/gallery/4.png'
import img5 from '../assets/gallery/5.png'
import img6 from '../assets/gallery/6.png'
import img7 from '../assets/gallery/7.png'
import img8 from '../assets/gallery/8.png'
import img9 from '../assets/gallery/9.png'
import img10 from '../assets/gallery/10.png'
import img11 from '../assets/gallery/11.png'
import img12 from '../assets/gallery/12.png'
import img13 from '../assets/gallery/13.jpg'
import img14 from '../assets/gallery/14.jpg'
import img15 from '../assets/gallery/15.jpg'
import img16 from '../assets/gallery/16.jpg'
import img17 from '../assets/gallery/17.jpg'
import img18 from '../assets/gallery/18.png'
import img19 from '../assets/gallery/19.png'
import img20 from '../assets/gallery/20.png'
import img21 from '../assets/gallery/21.png'
import img22 from '../assets/gallery/22.jpg'
import img23 from '../assets/gallery/23.jpg'
import img24 from '../assets/gallery/24.jpg'
import img25 from '../assets/gallery/25.jpg'
import img26 from '../assets/gallery/26.jpg'
import img27 from '../assets/gallery/27.jpg'
import img28 from '../assets/gallery/28.jpg'
import img29 from '../assets/gallery/29.png'


function Services() {

  return (
    <div className='services-section'>
      {/* <div >
        <h1 className="thp green">How we got here</h1>
        <p>See how far we  have come</p>
      </div> */}

      <Carousel className='gallery'>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img4}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img5}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img6}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img7}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img8}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img9}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img10}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img11}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img12}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img13}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img14}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img15}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img16}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img17}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img18}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img19}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img20}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img21}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img22}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img23}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img24}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img25}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img26}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img27}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img28}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img29}
            alt="Second slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Services;

// // import React from "react";


// /* popout the browser and maximize to see more rows! -> */

